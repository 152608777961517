import React from "react"

const SendMessage = (props) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.7796 0.220423C17.9848 0.425656 18.0537 0.730626 17.9567 1.00416L12.1036 17.4991C12.0031 17.7823 11.7438 17.9785 11.444 17.9984C11.1442 18.0182 10.8614 17.8577 10.7245 17.5902L7.23337 10.7666L0.409761 7.27548C0.142277 7.13863 -0.018161 6.85578 0.00164455 6.55597C0.021449 6.25617 0.217701 5.99688 0.500862 5.8964L16.9958 0.0433442C17.2694 -0.0537166 17.5743 0.0151886 17.7796 0.220423ZM8.71191 10.3523L11.2691 15.3504L16.0099 1.99014L2.64956 6.73089L7.64772 9.28809L10.2516 6.68416L11.3158 7.74835L8.71191 10.3523Z" fill="#70BBFF"/>
        </svg>
    )
}

export default SendMessage


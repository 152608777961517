import React from "react";
import PropTypes from "prop-types";
import style from "./NotificationSetting.module.sass";
import PatientHomeNotification from "./PatientHomeNotification/PatientHomeNotification";
import ReportNotifications from "./ReportNotifications/ReportNotifications";
import LanguageChanger from "./LanguageChanger/LanguageChanger";

function NotificationSetting(props){
  const {licenses} = props;

  return (
  <div className={`${style.NotificationSetting}`}>
    {licenses.includes('euleria_home') &&
      <div>
        <PatientHomeNotification />
        <hr className={`white`} />
        <ReportNotifications />
        <hr className={`white`} />
      </div>
    }
    <LanguageChanger />
  </div>
  );
}

NotificationSetting.propTypes = {
};

NotificationSetting.defaultProps = {
};

export default NotificationSetting
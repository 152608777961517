import React, { useEffect, useState } from "react";
import DetailsCall from "../DetailsCall/DetailsCall";
import classes from "./Call.module.sass";

function Call(props) {
  const { 
    date, 
    durationLabel, 
    icon, 
    isOpen, 
    url,
    weekDay } = props;

  const [showDetailCall, setShowDetailCall] = useState(false);

  useEffect(() => {
    if (isOpen) {
      clickOnTop()
    }
  }, [isOpen])

  const clickOnTop = () => {
    setShowDetailCall(!showDetailCall)
  };

  return (
    <div className={`${classes.call}`}>
      <div className={`row ${classes.tile}`} 
           onClick={clickOnTop}>
        <div className={`col-10 ${classes.mainColumn}`}>
          <div className={classes.callIcon} dangerouslySetInnerHTML={{ __html: icon }} />
          <div className={classes.label}>{durationLabel}</div>
        </div>
        <div className={`col-2 p-0 ${classes.dateColumn}`}>
          <div className={classes.date}>{date}</div>
          <div className={classes.weekDay}>{weekDay}</div>
        </div>
      </div>
      <div>
        {showDetailCall ? <DetailsCall callURL={url} insideBox={true} /> : null}
      </div>
    </div>
  );
}

export default Call;

import React, { useState, useEffect, useRef } from "react";
import ExerciseSequenceChartEffort from '../ExerciseSequence/ExerciseSequenceChart/ExerciseSequenceChartEffort'
import ExerciseSequenceChartPulseOximeter from '../ExerciseSequence/ExerciseSequenceChart/ExerciseSequenceChartPulseOximeter'
import ExerciseResult from "../ExerciseResult/ExerciseResult";
import SliderDetails from "./SliderDetails";
import classes from "./Slider.module.sass";
import ExerciseFooterDownload from "../ExerciseFooterDownload/ExerciseFooterDownload";
import { callApi } from "../../../helpers";

function Slider(props) {
  const {
    colors,
    duration,
    initModal,
    isEuleriaLab,
    isMedicair,
    isOpen,
    leftShortText,
    medicairData,
    path,
    rightShortText,
    type } = props;

  // close, loading, open, error
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [statusSlider, setStatusSlider] = useState("close");

  const slider = useRef()

  useEffect(() => {
    if(isOpen) {
      toggleDetails()
    }
  }, [isOpen])

  async function submitForm() {
    return callApi(path, "GET", "json", (data) => {
      setData(data);
      setStatusSlider("open");
    });
  }

  async function toggleDetails() {
    if (isEuleriaLab) {
      const uniqueUrl = `${path}&rand=${Math.random().toString(36).substring(2, 15)}`;
      initModal(uniqueUrl, '');
      slider.current.click()
      return;
    }

    if (statusSlider == "close") {
      setStatusSlider("loading");
      try {
        await submitForm();
      } catch (err) {
        setStatusSlider("error");
        setError(err);
      }
    } else {
      setStatusSlider("close");
      setData(null);
    }
  }

  const currentCssClass = () => {
    if (statusSlider == "open") {
      return `${classes.bgGradient} ${classes.sliderOpen}`;
    }
    return `${classes.bgGradient} ${classes.sliderClose}`;
  };

  const renderExercisesResults = () => {
    return data.exercises_results.map((el) => {
      return (
        <ExerciseResult
          {...el}
          colors={colors}
          key={el.index}
          leftShortText={leftShortText}
          rightShortText={rightShortText}
          type={type}
        />
      );
    });
  };

  const renderExerciseResultsMedicair = () => {
    return data.exercises_results.map((el) => {
      const effortData = data?.exercises_results[0].dataForEffortChart[0].results[0].samples[0]
      return (
        <div>
          {effortData.length != 0 && <ExerciseSequenceChartEffort
            title={medicairData.chartTitleEffort}
            effortData={effortData}
          />}
          <ExerciseSequenceChartPulseOximeter
            heartRateData={data?.exercises_results[0].dataForPulseOximeterChart[0].results[0].samples[0]}
            heartRateLabel={data?.exercises_results[0].dataForPulseOximeterChart[0].title}
            medicairData={medicairData}
            saturationData={data?.exercises_results[0].dataForPulseOximeterChart[1].results[0].samples[0]}
            saturationLabel={data?.exercises_results[0].dataForPulseOximeterChart[1].title}
            title={medicairData.chartTitlePulseOximeter}
          />
        </div>
      );
    });
  }

  const renderDuration = (time) => {
    if(time < 30 ) {
      return `${time} ${duration.secLabel}`
    }
    return `${Math.round((time || 0) / 60)} ${duration.minLabel}`
  }

  return (
    <div data-toggle={isEuleriaLab ? 'modal' : undefined}
         data-target={isEuleriaLab ? '#EuleriaLabActivitiesModal' : undefined}
         data-bs-toggle={isEuleriaLab ? 'modal' : undefined}
         data-bs-target={isEuleriaLab ? '#EuleriaLabActivitiesModal' : undefined} >
      <div className={currentCssClass()}
           onClick={toggleDetails}
           style={{ cursor: "pointer" }}
           ref={slider}>
        <SliderDetails 
          {...props}
          colors={colors}
          isMedicair={isMedicair}
          isEuleriaLab={isEuleriaLab}
          medicairData={medicairData}
          statusSlider={statusSlider}
          type={type}
        />
      </div>
      {statusSlider == "open" &&
        <div>
          {duration &&
            <div className={classes.duration}>
              <span className={classes.label}>{duration.realDurationLabel} </span>
              <span className={classes.value} style={{ color: colors.primaryColor }}>{renderDuration(duration.realDuration)} </span>
              <span>{duration.separator} </span>
              <span className={classes.value} style={{ color: colors.primaryColor }}>{renderDuration(duration.estimate)} </span>
              <span className={classes.label}>{duration.estimateLabel}</span>
            </div>
          }
          {data?.exercises_results && (isMedicair && renderExerciseResultsMedicair() || renderExercisesResults())}
          {data?.footer && <ExerciseFooterDownload {...data?.footer} colors={colors} />}
        </div>
      }
    </div>
  );
}

export default Slider;

import React, { Fragment } from "react";
import SliderProgressBar from "./SliderProgressBar";
import SliderStatus from "./SliderStatus";
import SliderIconTitleSubtitle from "./SliderIconTitleSubtitle";
import classes from "./SliderDetails.module.sass";
import ExercisesBar from "../ExercisesBar/ExercisesBar";
import HeartIcon from "./assets/heart.svg";
import OxygenSaturationIcon from "./assets/oxygen.svg";
import SliderToolIcon from "./SliderToolIcon";

function SliderDetails(props) {
  const {
    activityTag,
    agility,
    balance,
    colors,
    date,
    difficulty,
    exercises,
    gaitData,
    icdIndex,
    icsIndex,
    isMedicair,
    jumps,
    license,
    medicairData,
    modality,
    pathwayTag,
    returnToSport,
    score,
    speed,
    status,
    strength,
    sessionId,
    statusSlider,
    title,
  } = props;

  const renderMedicair = () => {
    return (
      <Fragment>
        <div className="col-2 d-flex align-self-center my-3 my-lg-0 text-center text-lg-left">
          <span className={classes.effort}>{medicairData.maxEffort}</span>
          <div className="mt-2">
            <div className={classes.effortLabel}>{medicairData.maxEffortLabelLine1}</div>
            <div className={classes.effortLabel}>{medicairData.maxEffortLabelLine2}</div>
          </div>
        </div>
        <div className="col-2 align-self-center my-3 my-lg-0">
          <SliderIconTitleSubtitle
            icon={HeartIcon}
            title={`${medicairData.heartRateSecondsInRange} min`}
            subtitle={medicairData.inRangeLabel}
          />
        </div>
        <div className="col-3 align-self-center my-3 my-lg-0">
          <SliderIconTitleSubtitle
            icon={OxygenSaturationIcon}
            title={`${medicairData.oxygenSaturationSecondsOutOfRange} min`}
            subtitle={medicairData.outOfRangeLabel}
          />
        </div>
        <div className="col-1 align-self-center my-3 my-lg-0">
          <SliderToolIcon
            toolCode={medicairData.toolUsedCode}
            toolUsed={medicairData.toolUsed}
          />
        </div>
      </Fragment>
    );
  };
  
  const scoreColumns = () => {
    let columns = 8
    if (title) { columns -= 3 }
    return columns
  }

  const paramsColumns = () => {
    let columns = 5
    if (balance && agility && strength && speed) { columns += 1 }
    if (gaitData && gaitData.duration && gaitData.steps) { columns += 1 }
    if (gaitData && gaitData.stance && gaitData.swing && gaitData.doubleSupport) { columns += 1 }
    if (exercises?.badges) {columns += 3}
    return columns
  }

  const rightColumns = () => {
    let columns = 2
    if (balance && agility && strength && speed) { columns -= 1 }
    if (gaitData && gaitData.duration && gaitData.steps) { columns -= 1 }
    if (gaitData && gaitData.stance && gaitData.swing && gaitData.doubleSupport) { columns -= 1 }
    return columns
  }

  const indexColors = (value) => {
    switch (value) {
      case 1:
        return classes.high
      case 2:
        return classes.mid
      case 3:
        return classes.low
    }
  } 

  const renderTitle = () => {
    return (
      <div className={`col-3 ${classes.activityTitle}`} 
            style={{ paddingRight: "15px", paddingLeft: "15px" }}>
        <div className={`auto-tooltip ${classes.title}`} 
             style={{ color: colors.primaryColor }}
             data-toggle="tooltip"
             title={title}>
          {title}
          </div>
        <div style={{ display: "flex" }}>
          <div className={classes.badge}>{activityTag}</div>
          {pathwayTag &&
            <div className={classes.pathwayTag}>{pathwayTag}</div>
          }
        </div>
      </div>
    )
  }

  const renderExerciseBagdes = () => {
    return (
      <div className="col-12 col-lg-11" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
        <ExercisesBar exercises={exercises.badges}
          colors={colors} />
      </div>
    )
  }

  const renderScore = () => {
    return (
      <div className={`col-${scoreColumns()} align-self-center`}>
        <div className="row">
          <Fragment>
            <div className={`col-2 ${title ? "col-lg-2" : "col-lg-1"}`}  style={{ paddingRight: "15px", paddingLeft: "15px" }}>
              <span className={classes.percentage}
                style={{ color: colors.primaryColor }}>
                {score.value}
              </span>
            </div>

            <div className={`col-10 ${title ? "col-lg-9" : "col-lg-10"} align-self-center`} style={{ paddingRight: "0px", paddingLeft: "15px" }}>
              <SliderProgressBar
                score={score.value}
                colors={colors}
                sessionId={sessionId}
                statusSlider={statusSlider}
              />
            </div>
          </Fragment>
          {exercises?.badges && renderExerciseBagdes()}
          <div className="col-12 col-lg-1">
            <SliderStatus {...status} />
          </div>
        </div>
      </div>
    )
  }

  const renderParameter = (parameter) => {
    return (
      <div className={`col-3 ${classes.infoTextLeft}`}>
        <div className={classes.paramLabel}>
          {parameter.label}
        </div>
        <div style={{ width: "76px" }}>
          <SliderProgressBar
            score={parameter.value}
            colors={colors}
            sessionId={sessionId}
            statusSlider={statusSlider}
            isDetail={true}
          />
        </div>
      </div>
    )
  }

  const renderGaitParameter = (parameter, seconds, percentage) => {
    return (
      <div className={`col-3 ${classes.infoTextLeft}`}>
        <div className={classes.paramLabel}>
          {parameter.label}
        </div>
        <div className={classes.paramTitle}>
          <span style={{ color: colors.primaryColor }}>
            {parameter.value}
          </span>
          {seconds && 
            <span className={classes.label} style={{ marginLeft: "3px", textTransform: "lowercase" }}>s</span>
          }
          {percentage && 
            <span className={classes.label} style={{ marginLeft: "3px", textTransform: "lowercase" }}>%</span>
          }
        </div>
      </div>
    )
  }

  const renderIcsParameter = (parameter) => {
    return (
      <div className={`col-5 ${classes.infoTextLeft}`}>
        <div className={classes.paramLabel}>
          {parameter.label}
        </div>
        <div className={`${classes.indexBadge} ${indexColors(parameter.value)}`}>
          {parameter.valueLabel}
        </div>
      </div>
    )
  }

  const renderRightColumnParameter = (parameter) => {
    return (
      <div className={`col-2 ${classes.infoTextRight}`} style={{ paddingRight: "20px" }}>
        <div className={classes.title} style={{ color: colors.primaryColor }}>
          {parameter.value}
        </div>
        <div className={classes.label}>
          {parameter.label}
        </div>
      </div>
    )
  }

  return (
    <div className="row" style={{ minHeight: license == "euleria_lab" ? "70px" : "60px", margin: "0" }}>
      {isMedicair && renderMedicair()}
      {!isMedicair && title && renderTitle()}
      {!isMedicair && score && renderScore()}

      {!isMedicair && !score &&
        <div className={`col-${paramsColumns()} align-self-center`}>
          <div className="row" style={{ paddingLeft: "15px" }}>
            {exercises?.badges && renderExerciseBagdes()}

            {balance && renderParameter(balance)}
            {agility && renderParameter(agility)}
            {strength && renderParameter(strength)}
            {speed && renderParameter(speed)}

            {gaitData && gaitData.duration && renderGaitParameter(gaitData.duration, true, false)}
            {gaitData && gaitData.steps && renderGaitParameter(gaitData.steps, false, false)}
            {gaitData && gaitData.stance && renderGaitParameter(gaitData.stance, false, true)}
            {gaitData && gaitData.swing && renderGaitParameter(gaitData.swing, false, true)}
            {gaitData && gaitData.doubleSupport && renderGaitParameter(gaitData.doubleSupport, false, true)}

            {icsIndex && renderIcsParameter(icsIndex)}
            {icdIndex && renderIcsParameter(icdIndex)}
          </div>
        </div>
      }

      {exercises &&
        <div className={`col-2 ${classes.infoTextRight}`} style={{ paddingRight: "20px" }}>
          <div className={classes.title} style={{ color: colors.primaryColor, textTransform: "lowercase" }}>
            {exercises.localizedData}
          </div>
          <div className={classes.label}>
            {exercises.label}
          </div>
        </div>
      }

      {!isMedicair && modality && renderRightColumnParameter(modality)}
      {!isMedicair && difficulty && renderRightColumnParameter(difficulty)}
      {!isMedicair && jumps && renderRightColumnParameter(jumps)}

      {!isMedicair && returnToSport &&
        <div className={`col-1 ${classes.infoTextRight}`} style={{ paddingRight: "20px" }}>
          <div className={classes.returnToSport}>
            {returnToSport == "Ok" && <span className={classes.ok}>{returnToSport}</span>}
            {returnToSport == "No" && <span className={classes.no}>{returnToSport}</span>}
          </div>
        </div>
      }
      {!isMedicair && !exercises && !modality && !difficulty && !jumps && !returnToSport &&
        <div className={`col-${rightColumns()} ${classes.infoTextRight}`} style={{ paddingRight: "20px" }}></div>
      }
      <div className={`col-2 ${classes.infoTextRight} ${classes.date}`} style={{ paddingRight: "15px", paddingLeft: "20px" }}>
        <div className={classes.title}>{date.fulldate}</div>
        <div className={classes.label}>{date.weekday}</div>
      </div>
    </div>
  );
}

export default SliderDetails;

import React, { useState, useRef, useEffect } from "react"
import style from "./ProfessionalProfile.module.sass"
import camera from "../../../../assets/images/camera.svg"
import trash from "../../../../assets/images/red-trash-without-circle.svg"
import upload from "../../../../assets/images/upload.svg"

function ProfessionalProfile(props) {
  const {avatar, currentProfessional, licenses, logoutUrl, translations} = props

  const ref = useRef();
  const avatarForm = useRef()
  const avatarInput = useRef()

  const [imageHover, setImageHover] = useState(false)
  const [showUploadMenu, setShowUploadMenu] = useState(false)

  const renderAvatar = () => {
    return (
      <div className={style.professionalAvatar}
           style={avatar.present ? {backgroundImage: `url(${avatar.url})`, backgroundSize: "cover"} : {}}
           onMouseEnter={() => setImageHover(true)}
           onMouseLeave={() => setImageHover(false)}>
        <div className={`${style.changeAvatar} ${imageHover ? "" : "d-none"}`}
              onClick={() => setShowUploadMenu(!showUploadMenu)}>
          <img src={camera} className={style.camera}></img>
          <span className={style.text}>
            {!avatar.present && translations.addAvatar}
            {avatar.present && translations.changeAvatar}
          </span>
        </div>
      </div>
    )
  }

  const activeLicense = (license) => {
    return (
      <div className={style.infoRow}>
        <div className={style.label}>{license}</div>
        <div className={style.info} style={{textTransform: "lowercase"}}>
          <span className={style.greenDot}></span>
          {translations.active}
        </div>
      </div>
    )
  }

  const notRequiredLicense = (license) => {
    return (
      <div className={style.infoRow}>
        <div className={style.label}>{license}</div>
        <div className={style.info}>
          {translations.notRequired}
        </div>
      </div>
    )
  }

  const renderLicenseState = (license) => {
    switch (license) {
      case "Riablo":
        if (licenses.active.includes("euleria_lab")) {
          return activeLicense(license)
        }else{
          return notRequiredLicense(license)
        }
      case "xClinic":
        if (licenses.active.includes("xclinic")) {
          return activeLicense(license)
        }else{
          return notRequiredLicense(license)
        }
      case "Kari":
        if (licenses.active.includes("euleria_home")) {
          return activeLicense(license)
        }else{
          return notRequiredLicense(license)
        }
      case "Kari Konnect":
        if (licenses.activeKK != null) {
          return activeLicense(license)
        }else{
          return notRequiredLicense(license)
        }
    }
  }

  const renderUploadMenu = () => {
    return (
      <div className={style.uploadMenu} 
           style={avatar.present ? {top: "-47px"} : {top: "-25px"}}
           ref={ref}>
        <div className={style.upload}
             onClick={() => avatarInput.current.click()}>
          <form action={avatar.uploadPath}
                method="post"
                encType="multipart/form-data"
                className="d-none" 
                id="avatar-form"
                ref={avatarForm}>
            <input type="hidden" name="_method" value="patch"/>
            <input type="file" 
                   accept=".jpg, .jpeg, .png, .gif, .webp"
                   id="professional[avatar]" 
                   name="professional[avatar]"
                   ref={avatarInput}
                   onChange={() => avatarForm.current.submit()}/>
          </form>
          <div className={style.iconContainer}>
            <img src={upload} className={style.icon}/>
          </div>
          {!avatar.present && 
            <div>
              {translations.updateAvatar}
            </div>
          }
          {avatar.present && 
            <div>
              {translations.changeAvatar}
            </div>
          }
        </div>
        {avatar.present &&
          <a className={style.delete} href={avatar.delete} data-method="delete">
            <div className={style.iconContainer}>
              <img src={trash} className={style.icon}/>
            </div>
            <div>
              {translations.deleteAvatar}
            </div>
          </a>
        }
      </div>
    )
  }

  useOnClickOutside(ref, () => setShowUploadMenu(false));

  return (
    <div className={style.professionalProfile}>
      {showUploadMenu && renderUploadMenu()}

      <div className={style.professionalName}>
        {currentProfessional.fullName}
      </div>

      {renderAvatar()}

      <div className={style.infoRow}>
        <div className={style.label}>{translations.center}</div>
        <div className={style.info}>{currentProfessional.center}</div>
      </div>
      <div className={style.infoRow}>
        <div className={style.label}>{translations.email}</div>
        <div className={style.info}>{currentProfessional.email}</div>
      </div>
      <div className={style.separator}></div>
      <div className={style.licensesState}>
        {translations.licensesState}
      </div>

      {renderLicenseState(translations.riablo)}
      {renderLicenseState(translations.xClinic)}
      {renderLicenseState(translations.kari)}
      {renderLicenseState(translations.kariKonnect)}

      <div className={style.logout}>
        <a className={`btn ${style.button}`}
           href={logoutUrl}
           data-method="delete">
          {translations.logout}
        </a>
      </div>
    </div>
  )
}

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}

export default ProfessionalProfile
import React, { useState } from "react";
import PropTypes from 'prop-types'; 
import style from "./Select.module.sass"

function Select(props) {
  const {
    options, 
    prompt, 
    setOption, 
    selectedOption } = props

  const [currentOption, setCurrentOption] = useState(selectedOption || prompt)
  const [showOptions, setShowOptions] = useState(false)
  const promptOption = { value: "", label: prompt }

  const selectOption = (option) => {
    setShowOptions(false)
    setCurrentOption(option.label)
    setOption(option)
  }
  
  return (
    <div className={style.select}>
      <div className={`select kari-select ${style.selectWrapper} ${showOptions ? style.selectFocused : ""}`}
           onClick={() => setShowOptions(!showOptions)}>
        <div className={style.selectedOption}>
          {currentOption}
        </div>
        {!showOptions && <i className={`fa-solid fa-angle-down ${style.selectIcon}`}></i>}
        {showOptions && <i className={`fa-solid fa-angle-up ${style.selectIcon}`}></i>}

      </div>
      {showOptions && 
        <div className={style.optionsWrapper}>
          {prompt && 
            <div className={style.option} 
                 onClick={() => selectOption(promptOption)}>
              {promptOption.label}
            </div>}
          {
            options.map(option => {
              return (
                <div className={style.option}
                     onClick={() => selectOption(option)}
                     key={option.key || option.value}>
                  {option.label}
                </div>
              )
            })
          }
        </div>
      }
    </div>
  )
}

Select.propTypes = {
  options: PropTypes.array.isRequired,
  prompt: PropTypes.string,
  setOption: PropTypes.func.isRequired,
  selected: PropTypes.string
}

export default Select
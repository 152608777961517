import React from "react"
import style from "./PatientForm.module.sass"
import { useState } from 'react'

function CommonFields(props) {
  const {
    //errors,
    fieldsTranslations,
    gender,
    inputErrorClass,
    patient,
    selectGender,
    userTypologies } = props

  const [selectedPathology, setSelectedPathology] = useState(patient.userTypology)

  const genderClass = (linkGender) => {
    if(linkGender == gender) {
      return `${style.radioButton} ${style.selectedRadio}`
    }
    return style.radioButton
  }

  const showSurgeryDate = () => {
    return selectedPathology == 3 ||
           selectedPathology == 4 ||
           selectedPathology == 5 ||
           selectedPathology == 8
  }

  const toggleSurgeryDate = () => {
    if(showSurgeryDate()) {
      return (
        <div>
          <span className={`${style.requiredField}`}>*</span>
          <div style={{display: 'inline', position: 'relative'}}>
            <span className={style.floatPlaceholder} style={{top: '-26px'}}>{fieldsTranslations.patientSurgeryDate}</span>
          </div>
          <input type='date'
                 placeholder={fieldsTranslations.patientSurgeryDate}
                 className={`${style.fullWidthInput} ${inputErrorClass('surgery_date')}`}
                 id='surgery_date'
                 name='surgery_date'
                 defaultValue={patient.surgeryDate}/>
          {/* <div className={style.inputError}>{errors["surgery_date"]}</div> */}
        </div>
      )
    }
    return ''
  }

  const defaultValueForSelect = (value) => {
    if(value != "" && value != null) {
      return value
    }
    return 'prompt'
  }

  const addInvalidClass = (e, field) => {
    e.preventDefault()
    document.getElementById(field).classList.add(style.invalidInput)
  }

  return (
    <span>
      <div style={{marginBottom: '15px'}}>
        <span className={`${style.requiredField}`}>*</span>
        <span className={`${style.radioWrapper} ${inputErrorClass('gender')}`}>
          <a href='javascript:void(0)'
             onClick={(e) => selectGender(e, 'male')}
             className={genderClass('male')}>
              {fieldsTranslations.male}
          </a>
          <a href='javascript:void(0)'
             onClick={(e) => selectGender(e, 'female')}
             className={genderClass('female')}>
              {fieldsTranslations.female}
          </a>
        </span>
      </div>
      {/* <div className={style.inputError}>{errors["gender"]}</div> */}
      <div>
        <span className={`${style.requiredField}`}>*</span>
        <input type='text'
               placeholder={fieldsTranslations.lastname}
               autoComplete="off"
               className={`${style.fullWidthInput} ${inputErrorClass('lastname')}`}
               defaultValue={patient.lastname}
               id='lastname'
               required
               onInvalid={(e) => addInvalidClass(e, 'lastname')}
               name='lastname'/>
        {/* <div className={style.inputError}>{errors["lastname"]}</div> */}
      </div>
      <div>
        <span className={`${style.requiredField}`}>*</span>
        <input type='text'
                placeholder={fieldsTranslations.name}
                autoComplete="off"
                className={`${style.fullWidthInput} ${inputErrorClass('name')}`}
                defaultValue={patient.name}
                id='name'
                required
                onInvalid={(e) => addInvalidClass(e, 'name')}
                 name='name'/>
        {/* <div className={style.inputError}>{errors["name"]}</div> */}
      </div>
      <div>
        <span className={`${style.requiredField}`}>*</span>
        <div style={{display: 'inline', position: 'relative'}}>
          <span className={style.floatPlaceholder}>{fieldsTranslations.birthDate}</span>
        </div>
        <input type='date'
               className={`${style.fullWidthInput} ${inputErrorClass('birth_date')}`}
               defaultValue={patient.birthDate || patient.birthDateDefault}
               id='birthdate'
               name='birth_date'/>
        {/* <div className={style.inputError}>{errors["birthdate"]}</div> */}
      </div>
      <div className={style.halfWidthInputWrapper}>
        <div>
          <span className={`${style.requiredField}`}>*</span>
          <input type='number'
                 placeholder={fieldsTranslations.height}
                 autoComplete="off"
                 className={`${style.halfWidthInput} ${inputErrorClass('height')}`}
                 defaultValue={patient.height}
                 id='height'
                 name='height'/>
          {/* <div className={style.inputError}>{errors["height"]}</div>  */}
        </div>   
        <div style={{width: "4%"}}></div>
        <div>
          <input type='number'
                 placeholder={fieldsTranslations.weight}
                 autoComplete="off"
                 className={`${style.halfWidthInput} ${inputErrorClass('weight')}`}
                 defaultValue={patient.weight}
                 id='weight'
                 name='weight'/>
          {/* <div className={style.inputError}>{errors["weight"]}</div>  */}
        </div>
      </div>
        <span className={`${style.requiredField}`}>*</span>
        <select className={`select kari-select ${style.fullWidthInput} ${inputErrorClass('user_typology')}`}
                id='user_typology_id'
                name='user_typology_id'
              style={{marginBottom: "12px"}}
                onChange={(e) => setSelectedPathology(e.target.value)}
                defaultValue={defaultValueForSelect(patient.userTypology)}>
        <option value="prompt" disabled hidden>{fieldsTranslations.pathology}</option>
          {userTypologies.map(userTypology => {
            return(
              <option key={userTypology.id} value={userTypology.id}>{userTypology.code}</option>
            )
          })}
        </select>
        <i className={`fa-solid fa-angle-down ${style.dropDownIcon}`}></i>
        {/* <div className={style.inputError}>{errors["user_typology"]}</div> */}
      {toggleSurgeryDate()}
          <span className={`${style.requiredField}`}>*</span>
          <select className={`select kari-select ${style.fullWidthInput} ${inputErrorClass('side')}`}
                  id='side'
                  name='side'
                style={{marginBottom: "12px"}}
                  defaultValue={defaultValueForSelect(patient.side)}>
          <option value="prompt" disabled hidden>{fieldsTranslations.side}</option>
            <option value='none'>Nessuno</option>
            <option value='right'>Destro</option>
            <option value='left'>Sinistro</option>
          </select>
          <i className={`fa-solid fa-angle-down ${style.dropDownIcon}`}></i>
          {/* <div className={style.inputError}>{errors["side"]}</div>     */}
        <div>
          <textarea className={`${style.fullWidthInput}`}
                    placeholder={fieldsTranslations.notes}
                    defaultValue={patient.notes}
                    id='notes'
                    name='notes'
                    maxLength="160"
                    style={{resize: "none"}}/>
          {/* <div className={style.inputError}>{errors["notes"]}</div>     */}
        </div>
      </span>
  )
}

export default CommonFields
import React, { useState } from "react";
import style from "./PatientPage.module.sass";
import SinglePatient from "../SinglePatient/SinglePatient";
import PatientAttachments from "./PatientAttachments/PatientAttachments";
import PatientProfile from "./PatientProfile/PatientProfile";

function PatientPage(props) {
  const { 
    activityId, 
    actions, 
    archiveRedirectUrl, 
    archiveTranslations, 
    attachments, 
    back, 
    licenses, 
    patient, 
    patientTable, 
    searchActivityPlaceholderText, 
    tabs } = props.patientPage;

  const [selectedTab, setSelectedTab] = useState("profile");

  function PatientSidebar(tab) {
    if (tab == "attachments") {
      return (
        <PatientAttachments {...attachments} />
      );
    } else if (tab == "profile") {
      return (
        <PatientProfile patientTable={patientTable} />
      );
    }
  }

  const activeTab = (tab) => {
    if (tab == selectedTab) {
      return <div className={style.ActiveTab}></div>
    }
  }
  
  return (
    <div className={`${style.PatientPage} row text-white`}>
      <div className={`col-md-4 ${style.smallCol}`}>
        <div className={style.smallColWrapper}>
          <div className={`${style.ProfileTabs}`}>
            <div
              className={style.ProfileTab}
              onClick={() => setSelectedTab("profile")}>
              {tabs.profileLabel}
              {activeTab("profile")}
            </div>
            <div className={`${style.Separator}`}></div>
            <div
              className={style.ProfileTab}
              onClick={() => setSelectedTab("attachments")}>
              {tabs.attachmentsLabel}
              {activeTab("attachments")}
            </div>
          </div>
          {PatientSidebar(selectedTab)}
        </div>
      </div>
      <div className={`col-md-8`}>
        <a className={style.back} href={back.url}>
          {back.text}
        </a>
        <SinglePatient 
          patient={patient} 
          activityId={activityId}
          actions={actions}
          archiveRedirectUrl={archiveRedirectUrl}
          archiveTranslations={archiveTranslations}
          licenses={licenses}
          searchActivityPlaceholderText={searchActivityPlaceholderText}/>
      </div>
    </div>
  );
}

PatientPage.propTypes = {};

PatientPage.defaultProps = {};

export default PatientPage;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import style from "./LeftMenu.module.sass";

import Main from "../../icons/Main";
import KariPlans from "../../icons/KariPlans";
import Videocamera from "../../icons/Videocamera";
import Chats from "../../icons/Chats";
import SendMessage from "../../icons/SendMessage";
import Bell from "../../icons/Bell";
import Help from "../../icons/Help";
import Gear from "../../icons/Gear";
import News from "../../icons/News";
import EuleriaLabSequences from "../../icons/EuleriaLabSequences";
import EuleriaLabGrouptags from "../../icons/EuleriaLabGrouptags";
import MenuItem from "./MenuItem/MenuItem";
import ModalUpdates from "../ModalUpdates/ModalUpdates";
import { callApi } from "../../helpers";

function LeftMenu(props) {
  const { items, itemsDown, logo, profile, dev, messageSimulation, licenses, versions } = props;
  const iconComponents = {
    Main,
    KariPlans,
    EuleriaLabSequences,
    EuleriaLabGrouptags,
    Chats,
    Videocamera,
    SendMessage,
    Bell,
    Help,
    News,
    Gear
  };

  const getIcon = (name) => {
    var IconComponent = iconComponents[name];
    return <IconComponent />;
  };

  const [modalUrl, setModalUrl] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [triggerButtons, setTriggerButtons] = useState([]);

  useEffect(() => {
    setTriggerButtons(["#newsTrigger"]);
  }, []);

  const initModal = (url, title) => {
    setModalUrl(url);
    setModalTitle(title);
  }

  const simulateMessage = (e) => {
    e.preventDefault()
    callApi(messageSimulation.url, "POST", "json")
  }

  return (
    <div>
      <div className="modal fade" id="EuleriaLabSequencesModal" tabIndex="-1" aria-labelledby="EuleriaLabSequencesModalLabel" aria-hidden="true">
        <div className={`modal-dialog modal-xl ${style.ModalLink}`}>
          <div className={`modal-content ${style.ModalContent}`}>
            <div className={`modal-header ${style.ModalHeader}`}>
              <h1 className="modal-title" id="EuleriaLabSequencesModalLabel">{modalTitle}</h1>
              <button type="button" className="btn-close" data-dismiss="modal" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <iframe className="w-100 h-100" src={modalUrl}></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className={`${style.LeftMenu}`} id="navbar">
        <a href={logo.url}>
          <div className={`${style[logo.className]}`} />
        </a>
        <div className={style.upItems}>
        <ul>
          {items.map((item) => {
            return item.hide ? null : (
              <MenuItem key={item.icon} {...item} initModal={initModal}>
                {getIcon(item.icon)}
              </MenuItem>
            );
          })}
        </ul>
        </div>
        <div className={style.downItems}>
          <ul>
            {dev &&
              <li
                className={`auto-tooltip ${style.sendMessage}`}
                data-toggle="tooltip"
                data-placement="right"
                title={messageSimulation.tooltip}
                onClick={simulateMessage}
              >
                {getIcon(messageSimulation.icon)}
              </li>
            }
            {itemsDown.map((item) => {
              return (
                <MenuItem key={item.icon} {...item} initModal={initModal}>
                  {getIcon(item.icon)}
                </MenuItem>
              )
            })}
          </ul>
          <div className={`mx-auto ${style.profile}`} style={profile.active ? {borderColor: "#70BBFF"} : {}}>
            <a
              href={profile.url}
              className={`auto-tooltip`}
              data-toggle={"tooltip"}
              data-placement={"right"}
              title={profile.tooltip}
            >
              <img
                src={profile.avatar ? profile.avatar : profile.defaultAvatar}
                >
              </img>
            </a>
          </div>
        </div>
      </div>
      {triggerButtons.length > 0 ? (
        <ModalUpdates
          triggerButtons={triggerButtons}
          licenses={licenses}
          versions={versions}
          snapToElement={"#navbar"}
        />
      ) : null}
    </div>
  );
}

LeftMenu.propTypes = {};

LeftMenu.defaultProps = {};

export default LeftMenu;

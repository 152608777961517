import React from 'react'
import style from './PathwayForm.module.sass'
import { useState, useEffect } from 'react'
import Spinner from '../PatientForm/Spinner'

function PathwayForm(props) {
  const {
    action,
    selectedFocus,
    selectedBodyPart,
    selectedEvalInfo,
    selectedDurationWeeks,
    selectedIntermediateWeek,
    startDate,
    patientId,
    patientUrl,
    bodyParts,
    evalInfos,
    focusPathways,
    submitUrl,
    translations,
    evalInfosFocusPathway
  } = props
  const [errors, setErrors] = useState({})
  const [weeks, setWeeks] = useState(selectedDurationWeeks || 4)
  const [selectedWeeks, setSelectedWeeks] = useState(selectedIntermediateWeek || (weeks/2))
  const [recommendedExercise, setRecommendedExercise] = useState(0)
  const [selectedFocusValue, setSelectedFocusValue] = useState(selectedFocus)
  const [selectedBodyPartValue, setSelectedBodyPartValue] = useState(selectedBodyPart)
  const [selectedEvalInfoValue, setSelectedEvalInfoValue] = useState(selectedEvalInfo)
  const [buttonClick, setButtonClick] = useState(false)
  
  useEffect(() => {
    if(weeks < selectedWeeks) {
      setSelectedWeeks(weeks)
    }else{
      if(selectedIntermediateWeek == null){
        setSelectedWeeks(parseInt(weeks/2))
      }
    }
  }, [weeks])

  useEffect(() => {
    if(selectedFocusValue != 'prompt' && selectedBodyPartValue != 'prompt') {
      evalInfosFocusPathway.forEach(exercise => {
        if(exercise.focus == selectedFocusValue && exercise.bodyPart == selectedBodyPartValue) {
          setRecommendedExercise(exercise.test_id)
          setSelectedEvalInfoValue(exercise.test_id)
          return
        }
      });
    }
  }, [selectedFocusValue, selectedBodyPartValue])

  const preventEnterReload = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  const goToPatientShow = () => {
    window.location.href = patientUrl
  }

  const submitForm = async (e) => {
    e.preventDefault()
    setButtonClick(true)
    let params = Array.from(e.target)
      .filter(el => el.name)
      .reduce((a, b) => ({ ...a, [b.name]: parseInt(b.value) }), {})
      
    params.duration_weeks = weeks
    params.intermediate_week = parseInt(selectedWeeks)
    params.start_date = startDate
    params.patient_id = patientId 
    
    const formData = new FormData()
    formData.pathway = params

    let response = await fetch(submitUrl, {
      method: action,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })

    const data = await response.json()

    if (response.ok) {
      window.location.href = data.location
    }else{
      setButtonClick(false)
      setErrors({...data.errors})
    }
  }

  const buttonClass = () => {
    if (Object.keys(errors).length) {
      return `${style.buttonSubmit} ${style.buttonSubmitWithErrors}`
    }
    return style.buttonSubmit
  }

  const buttonSend = () => {
    if (!buttonClick) {
      return (
        <input type="submit"
               value={translations.submitText}
               className={buttonClass()}>
        </input>
      )
    }else{
      return (
        <a className={buttonClass()}
           disabled>
          <Spinner/>
        </a>
      )
    }
  }

  return (
    <div className={style.pathwayFormWrapper}>
      <div className={style.formTitle}>{translations.title}</div>
      <form onSubmit={(e) => submitForm(e)}>
        <div className={style.description}
             dangerouslySetInnerHTML = {{__html: translations.focusPathwayDescription}}>
        </div>
        <select id='focus_pathway_id'
                name='focus_pathway_id'
                className="select kari-select"
                value={selectedFocusValue || 'prompt'}
                onChange={(e) => setSelectedFocusValue(parseInt(e.target.value))}>
          <option value='prompt' hidden>{translations.focusPathwayPrompt}</option>
          {focusPathways.map(focusPathway => {
            return (
              <option value={focusPathway.id} 
                      key={focusPathway.id}>
                {focusPathway.name}
              </option>
            )
          })}
        </select>
        <i className={`fa-solid fa-angle-down ${style.dropDownIcon}`}></i>
        <div className={style.inputError}>{errors["focus_pathway"]}</div>
        <div className={style.description}>
          {translations.bodyPartDescription}
        </div>
        <select id='body_part_id'
                name='body_part_id'
                className="select kari-select"
                value={selectedBodyPartValue || 'prompt'}
                onChange={(e) => setSelectedBodyPartValue(parseInt(e.target.value))}>
          <option value='prompt' hidden>{translations.bodyPartPrompt}</option>
          {bodyParts.map(part => {
            return (
              <option value={part.id} 
                      key={part.id}>
                {part.name}
              </option>
            )
          })}
        </select>
        <i className={`fa-solid fa-angle-down ${style.dropDownIcon}`}></i>
        <div className={style.inputError}>{errors["body_part"]}</div>
        <div className={style.description}
             dangerouslySetInnerHTML = {{__html: translations.evalInfoDescription}}>
        </div>
        <select id='eval_info_id'
                name='eval_info_id'
                className="select kari-select"
                value={selectedEvalInfoValue || 'prompt'}
                onChange={(e) => parseInt(setSelectedEvalInfoValue(e.target.value))}>
          <option value='prompt' hidden>{translations.evalInfoPrompt}</option>
          {evalInfos.map(evalInfo => {
            return (
              <option value={evalInfo.id} 
                      key={evalInfo.id}>
                {recommendedExercise == evalInfo.id && 
                  "⭐️ "
                }
                {evalInfo.name}
              </option>
            )
          })}
        </select>
        <i className={`fa-solid fa-angle-down ${style.dropDownIcon}`}></i>
        <div className={style.description}>
          {translations.sliderDescription}
        </div>
        <div className={style.sliderContainer}>
          <div className={style.startingValue}>
            {translations.initialEvaluation}
          </div>
          <div className={style.middleValue}>
            {translations.middleEvaluationPart}
            <strong> {selectedWeeks} </strong>
            {translations.weeks}
          </div>
          <div className={style.endingValue}>
            {translations.finalEvaluation}
          </div>
          <br/>
          <div className={style.startingValue}
               style={{paddingTop: '12px', fontSize: '18px'}}>
            0
          </div>
          <div className={style.middleValue} style={{paddingTop: '13px'}}>
            <input type="range"
                   min="0"
                   max={weeks}
                   value={selectedWeeks}
                   className={style.slider}
                   id="myRange"
                   onChange={(e) => setSelectedWeeks(parseInt(e.target.value))}
                   />
          </div>
          <div className={style.endingValue}>
            <input type='number'
                   defaultValue={weeks}
                   onChange={(e) => setWeeks(parseInt(e.target.value))}
                   onKeyDown={(e) => preventEnterReload(e)}></input>
            <span>{translations.weeks}</span>
          </div>
        </div>
        <div className={style.submitContainer}>
          {buttonSend()}
        </div>
      </form>
      <div className={style.submitContainer}>
        <button className={style.abortSubmit}
                onClick={() => goToPatientShow()}>
          <div className={style.buttonText}>
            {translations.abortText}
          </div>
        </button>
        <div className={style.buttonSubText}>
          {translations.abortSubtext}
        </div>
      </div>
    </div>
  )

}

export default PathwayForm
import React, { useState } from "react";
import List from "../List/List";
import NotificationPreview from "./NotificationPreview/NotificationPreview";
import style from "./NotificationsBoxPreview.module.sass";

function NotificationsBoxPreview(props) {
  const { 
    baseUrl, 
    othersText, 
    otherUrl } = props;
  const [queryUrl, setQueryUrl] = useState(baseUrl);

  const onRemoveItem = () => {
    refreshLists();
  };

  const refreshLists = () => {
    let newQueryUrl = baseUrl + "&random=" + Math.random();
    setQueryUrl(newQueryUrl);
  };

  const renderNotification = (item) => {
    return (
      <NotificationPreview key={item.id} {...item} onRemove={onRemoveItem} />
    );
  };

  return (
    <div>
      <div className={style.notifications}>
        <List
          baseQuery={queryUrl}
          renderItem={renderNotification}
          showPagination={false}
          />
      </div>
      <div className={style.otherWrapper}>
        <a className={style.other} href={otherUrl}>{othersText}</a>
      </div>
    </div>
  );
}

NotificationsBoxPreview.propTypes = {};

NotificationsBoxPreview.defaultProps = {};

export default NotificationsBoxPreview;

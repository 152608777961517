import React, { useContext, useEffect, useRef, Fragment } from "react";
import style from "./EditPlanSummaryBlock.module.sass";
import { EditPlanContext } from "../EditPlan";
import Button from "../../Button/Button";
import TimingBlock from "./TimingBlock/TimingBlock";
import SummuaryExercisesList from "./SummuaryExercisesList/SummuaryExercisesList";
function EditPlanSummaryBlock(props) {
  const {} = props;
  const { state, dispatcher, removeItem } = useContext(EditPlanContext);
  const contentRef = useRef();

  useEffect(() => {
    window.addEventListener("resize", onResize.bind(this));
    onResize();
  }, []);

  useEffect(() => {
    onResize();
  });

  const renderLists = () => {
    return state.districts.map((district) => {
      const sameDistrict = district.id == state.district;
      return (
        <SummuaryExercisesList
          sameDistrict={sameDistrict}
          district={district}
          key={`district_list_${district.id}`}
          removeItem={removeItem}
        />
      );
    });
  };

  const getTotalExercises = () => {
    let total = 0;
    state.districts.map((district) => {
      total += district.items.length;
    });
    return total;
  };

  const onResize = () => {
    if (contentRef.current) {
      const blockBoundingBox = contentRef.current.getBoundingClientRect();
      const newHeight = window.innerHeight - blockBoundingBox.y;
      contentRef.current.style.height = `${newHeight}px`;
    }
  };

  const changeDistrict = (id) => {
    dispatcher({
      type: "update_district",
      district: id,
    });
  };

  const renderPrevNextButtons = () => {
    return state.districts.map((district, index) => {
      const leftRightArrow = index == 0 ? "right" : "left";
      const sameDistrict = state.district == district.id;
      const changeId = state.districts[Math.abs(index - 1)].id;
      return sameDistrict
        ? renderChangeDistrictBtn(
            changeId,
            district.button.title,
            leftRightArrow
          )
        : null;
    });
  };

  const renderChangeDistrictBtn = (id, text, leftRightArrow) => {
    return (
      <Button
        key={`button_${id}`}
        karyBtn
        kariBtnGreenOver
        kariBtnArrowLeft={leftRightArrow !== "right"}
        kariBtnArrowRight={leftRightArrow == "right"}
        onClick={() => changeDistrict(id)}
      >
        {text}
      </Button>
    );
  };

  const renderCustomizeBtn = () => {
    return (
      <Button
        karyBtn
        kariBtnGreenOver
        kariBtnArrowRight
        kariBtnDisable={getTotalExercises() == 0}
        href={state.customizeUrl}
        waitContent={"wait"}
      >
        {state?.customizeText}
      </Button>
    );
  };

  if (state.districts.length == 0) {
    return <div></div>;
  }

  const lastPage =
    state.districts.length == 1 || state.districts[1].id == state.district;

  return (
    <div className={`${style.EditPlanSummaryBlock}`}>
      <div className={`${style.yourPlan}`}> {state?.translations?.title}</div>
      <div className={`${style.EditPlanSummaryBlockContent}`} ref={contentRef}>
        {renderLists()}
        <div className={`${style.buttons}`}>
          {state.districts.length > 1 && renderPrevNextButtons()}
          {lastPage && renderCustomizeBtn()}
        </div>
        <TimingBlock
          durationText={state.durationText}
          duration={state.duration}
          message={state.durationWarning}
        />
      </div>
    </div>
  );
}

EditPlanSummaryBlock.propTypes = {};

EditPlanSummaryBlock.defaultProps = {};

export default EditPlanSummaryBlock;

import React from "react";

const EuleriaLabSequences = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.797 22.5H24L21.2334 17.7857C22.685 17.2371 23.6584 15.9343 23.6584 14.3057C23.6584 12.0943 21.9507 10.5 19.6452 10.5H15V22.5H16.9469V18.06H19.2182L21.797 22.5ZM16.9469 12.3514H19.5256C20.8235 12.3514 21.6603 13.1743 21.6603 14.2886C21.6603 15.42 20.8235 16.2257 19.5256 16.2257H16.9469V12.3514Z" fill="#F5FAFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9 3C9 2.58579 9.33579 2.25 9.75 2.25H22.75C23.1642 2.25 23.5 2.58579 23.5 3C23.5 3.41421 23.1642 3.75 22.75 3.75H9.75C9.33579 3.75 9 3.41421 9 3Z" fill="#F5FAFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9 11C9 10.5858 9.34737 10.25 9.77586 10.25H11.2241C11.6526 10.25 12 10.5858 12 11C12 11.4142 11.6526 11.75 11.2241 11.75H9.77586C9.34737 11.75 9 11.4142 9 11Z" fill="#F5FAFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9 19C9 18.5858 9.34538 18.25 9.77143 18.25H11.2286C11.6546 18.25 12 18.5858 12 19C12 19.4142 11.6546 19.75 11.2286 19.75H9.77143C9.34538 19.75 9 19.4142 9 19Z" fill="#F5FAFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9 6C9 5.58579 9.33579 5.25 9.75 5.25H17.75C18.1642 5.25 18.5 5.58579 18.5 6C18.5 6.41421 18.1642 6.75 17.75 6.75H9.75C9.33579 6.75 9 6.41421 9 6Z" fill="#F5FAFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9 14C9 13.5858 9.33892 13.25 9.75701 13.25H11.243C11.6611 13.25 12 13.5858 12 14C12 14.4142 11.6611 14.75 11.243 14.75H9.75701C9.33892 14.75 9 14.4142 9 14Z" fill="#F5FAFF"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M9 22C9 21.5858 9.33892 21.25 9.75701 21.25H11.243C11.6611 21.25 12 21.5858 12 22C12 22.4142 11.6611 22.75 11.243 22.75H9.75701C9.33892 22.75 9 22.4142 9 22Z" fill="#F5FAFF"/>
      <rect x="0.75" y="1.25" width="5.5" height="5.5" rx="1.25" stroke="#F5FAFF" strokeWidth="1.5"/>
      <rect x="0.75" y="9.25" width="5.5" height="5.5" rx="1.25" stroke="#F5FAFF" strokeWidth="1.5"/>
      <rect x="0.75" y="17.25" width="5.5" height="5.5" rx="1.25" stroke="#F5FAFF" strokeWidth="1.5"/>
    </svg>
  );
};

export default EuleriaLabSequences;

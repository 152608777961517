import React, { useState, useEffect, useRef } from "react";
import style from "./PatientMenu.module.sass";
import rentals from "../../../../../assets/images/rentals.svg"
import trash from "../../../../../assets/images/red-trash-without-circle.svg"
import pencil from "../../../../../assets/images/empty-pencil.svg"
import objective from "../../../../../assets/images/star.svg"
import { callApi } from "../../../helpers";
import Modal from "../../Modal/Modal";

function PatientMenu(props) {
  const {
    actionsUrls,
    activeActions,
    archiveRedirect,
    archiveTranslations,
    patientName,
    translations
  } = props;

  const [archiveState, setArchiveState] = useState("")
  const [enableDelete, setEnableDelete] = useState(false)
  const [showModal, setShowModal] = useState(false);

  const ref = useRef();


  const handleClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowModal(!showModal)
  }

  const actionClick = (e, url) => {
    e.stopPropagation()
    window.location.href = url
  }

  const archivePatient = (e, url) => {
    fetch(url, {method: 'POST'})
      .then((response) => {
        if (response.status == 200) {
          e.stopPropagation()
          setArchiveState("success")
        } else {
          e.stopPropagation()
          setArchiveState("error")
        }
      })
    e.preventDefault();
    e.stopPropagation();
  }

  useOnClickOutside(ref, () => setShowModal(false));

  const enableDeleteButton = (e) => {
    e.stopPropagation();
    setEnableDelete(!enableDelete);
  }

  const cancelPatientArchive = (e) => {
    e.stopPropagation()
    setEnableDelete(false)
  }

  const closeArchiveModal = (e) => {
    e.stopPropagation()
    setEnableDelete(false)
    setShowModal(false)
    archiveRedirect()
  }

  const dropDownClass = () => {
    if (activeActions.includes("show_sessions") || activeActions.includes("edit_sessions")) {
      return `${style.dropDown} ${style.large}`
    }
    return style.dropDown
  }

  const dropDownItem = (actionUrl, translation) => {
    return (
      <div className={`${style.dropDownItem}`}
           onClick={(e) => actionClick(e, actionUrl)}>
        <div className={style.iconContainer}>
          <img src={rentals} className={style.icon} />
        </div>
        <div style={{paddingTop: "5px", paddingBottom: "5px"}}>
          {translation}
        </div>
      </div>
    )
  } 

  return (
    <div className={style.patientMenu}>
      <div className={`${style.dotsContainer} ${showModal && style.dotsClicked}`}
        onClick={(e) => { handleClick(e) }}>
        <strong>•••</strong>
      </div>
      {showModal &&
        <div ref={ref} className={dropDownClass()}>
          {activeActions.find(a => a == "edit_sessions") && 
            dropDownItem(actionsUrls.assignRentals, translations.assignSessions)
          }
          {activeActions.find(a => a == "show_sessions") && 
            dropDownItem(actionsUrls.showRentals, translations.showSessions)
          }
          {(activeActions.find(a => a == "edit_sessions") ||
            activeActions.find(a => a == "show_sessions")) &&
            <div className={`${style.separator}`}>
            </div>
          }
          {activeActions.find(a => a == "edit_objective") && 
            dropDownItem(actionsUrls.editObjective, translations.editObjective)
          }
          {dropDownItem(actionsUrls.editProfile, translations.editProfile)}
          
          <div className={`${style.dropDownDeleteItem}`}
               id="confirmDelete">
            <div className={style.iconContainer}>
              <img src={trash} className={style.icon} />
            </div>
            <div style={{paddingTop: "5px", paddingBottom: "5px"}}>
              {translations.deleteUser}
            </div>
          </div>
          <Modal 
            background={true}
            closeButtons={["#cancelArchive"]}
            closeOnBackgroundClick={false}
            triggerButtons={["#confirmDelete"]} >
            <div className={style.deleteModal} onClick={(e) => e.stopPropagation()}>
              {archiveState == "" &&
                <div>
                  <div className={style.title}>
                    {archiveTranslations.areYouSure}
                  </div>
                  <div className={style.subTitle}>
                    {archiveTranslations.patientData1} <span className={style.patientName}>{patientName}</span> {archiveTranslations.patientData2}
                  </div>
                  <div className={style.disclaimer}>
                    {archiveTranslations.patientConsent}
                  </div>
                  <div className={style.checkboxWrapper}>
                    <input type="checkbox" 
                          id="confirmCheck"
                          onChange={(e) => enableDeleteButton(e)}/>
                    <label htmlFor="confirmCheck"> {archiveTranslations.confirm}</label>
                  </div>
                  <div className={style.buttonsWrapper}>
                    <div className={style.cancel}
                        id="cancelArchive"
                        onClick={(e) => cancelPatientArchive(e)}>
                      {archiveTranslations.cancel}
                    </div>
                    <div className={enableDelete ? style.delete : style.disabledDelete}
                          onClick={(e) => archivePatient(e, actionsUrls.deleteUser)}>
                      {archiveTranslations.delete}
                    </div> 
                  </div>
                </div>
              }
              {archiveState == "success" &&
                <div className={style.onResponse}>
                  <div className={style.successTitle}> {archiveTranslations.success}</div>
                  <div className={style.subTitle}>
                    {archiveTranslations.successMessage}
                  </div>
                  <div className={style.disclaimer}>
                    {archiveTranslations.closeMessage}
                  </div>
                  <div className={style.cancel}
                        id="cancelArchive"
                        onClick={(e) => closeArchiveModal(e)}>
                    {archiveTranslations.close}
                  </div>
                </div>
              }
              {archiveState == "error" &&
                <div className={style.onResponse}>
                  <div>
                    <div className={style.title}> {archiveTranslations.error}</div>
                    <div className={style.subTitle}>
                      {archiveTranslations.errorMessage}
                      <br/>
                      <br/>
                      boh@mail.it
                    </div>
                    <div className={style.disclaimer}>
                    {archiveTranslations.closeMessage}
                    </div>
                    <div className={style.cancel}
                        id="cancelArchive"
                        onClick={(e) => closeArchiveModal(e)}>
                      {archiveTranslations.close}
                    </div>
                  </div>
                </div>
              }
            </div>
          </Modal>
        </div>
      }
    </div>
  )
}

// solution got from here: https://stackoverflow.com/a/68018429/1897170
function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}

PatientMenu.propTypes = {
};

export default PatientMenu;
